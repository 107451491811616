import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { Model } from 'survey-core';
import {
  VisualizationPanel,
  VisualizationManager,
  NpsVisualizer,
} from 'survey-analytics';
import 'survey-analytics/survey.analytics.css';

interface SurveyDialogProps {
  open: boolean;
  onClose: () => void;
  surveyResults: any[];
}

VisualizationManager.registerVisualizer('rating', NpsVisualizer);

export function SurveyDialog(props: SurveyDialogProps): JSX.Element {
  const { open, onClose, surveyResults } = props;
  const vizPanelContainer = useRef<HTMLDivElement>(null);
  const [model, setModel] = useState<Model | null>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (surveyResults && vizPanelContainer.current) {
          renderVisualization(surveyResults);
        }
      }, 100);
    }
  }, [open, surveyResults]);

  const renderVisualization = (results: any[]) => {
    if (!vizPanelContainer.current || results.length === 0) return;

    vizPanelContainer.current.innerHTML = '';

    // Extract questions from surveyResults
    const questions = new Set<string>();
    results.forEach(result => {
      Object.keys(result).forEach(question => questions.add(question));
    });

    // Survey JSON schema
    const surveyJson = {
      questions: Array.from(questions).map(question => ({
        name: question,
        type: 'text',
        title: question.charAt(0).toUpperCase() + question.slice(1).replace(/([A-Z])/g, ' $1')
      }))
    };

    try {
      const newModel = new Model(surveyJson);
      setModel(newModel);
      const resultsData = results.map(result => result);

      // Render VisualizationPanel
      const vizPanel = new VisualizationPanel(newModel.getAllQuestions(), resultsData, {
        haveCommercialLicense: true,
      });
      vizPanel.render(vizPanelContainer.current);
    } catch (error) {
      console.error('Error creating visualization panel:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Survey Analytics</DialogTitle>
      <DialogContent style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <div ref={vizPanelContainer} style={{ height: '500px', width: '100%' }}></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={{ borderRadius: '100px', textTransform: 'none' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useMemo, useState } from 'react';
import { Grid, Card, CardHeader, CardContent, Button, TableContainer, Table, TableRow, TableCell, TableFooter, TableHead, TableBody, TablePagination, IconButton, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getApplicationsByOrgId, getSurveyResultsByAppIdNoFilter, getSurveyResultsByAppIdWithFilter } from '../../api/applications/applications';
import ArchiveOutlined from '@mui/icons-material/Inventory2Outlined';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Inventory';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TableViewIcon from '@mui/icons-material/TableView';
import { TableSkeleton } from '../util.tsx/TableUtil';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { ApplicationDialog } from '../dialogs/ApplicationDialog';
import { Application } from '../../types/applications';
import { ArchiveApplicationDialog } from '../dialogs/ArchiveApplicationDialog';
import { SurveyDialog } from '../dialogs/SurveyDialog';
import { SurveyTableViewDialog } from '../dialogs/SurveyTableViewDialog';

interface OrgApplicationsProps {
  orgId: string;
}

export default function OrgApplications(props: OrgApplicationsProps): JSX.Element {
  const { orgId } = props;
  const { data: applications, isLoading, refetch } = useQuery({
    queryKey: ['applications'],
    queryFn: () => getApplicationsByOrgId(orgId),
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [currentApp, setCurrentApp] = useState({} as Application);
  const [edit, setEdit] = useState(false);
  const [openArchiveDialog, setOpenArchive] = useState(false);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openSurveyDialog, setOpenSurveyDialog] = useState(false);
  const [openSurveyTableViewDialog, setOpenSurveyTableViewDialog] = useState(false);
  const [surveyResults, setSurveyResults] = useState(null);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  const editApp = (app: Application): void => {
    setEdit(true);
    setCurrentApp(app);
    localStorage.setItem('form-edit', JSON.stringify(app))
    setOpenNewDialog(true);
  };

  const createNewApp = () => {
    localStorage.removeItem('form-edit');
    localStorage.removeItem('form-pending');
    setEdit(false);
    setCurrentApp(null);
    setOpenNewDialog(true);
  };

  const visibleRows = useMemo(
    () => applications?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, applications],
  );

  const handleArchivedApp = (app: Application): void => {
    setCurrentApp(app);
    setOpenArchive(true);
  };

  const handleOpenSurveyDialog = async (app: Application): Promise<void> => {
    setIsLoadingDialog(true);
    const results = await getSurveyResultsByAppIdNoFilter(app.id);
    setSurveyResults(results);
    setIsLoadingDialog(false);
    setOpenSurveyDialog(true);
  };
  

  const handleOpenSurveyTableViewDialog = async (app: Application): Promise<void> => {
    setCurrentApp(app);
    const results = await fetchSurveyResults(app.id);
    setSurveyResults(results);
    setOpenSurveyTableViewDialog(true);
  };


  const fetchSurveyResults = async (appId: number, fromDate?: string, toDate?: string): Promise<any> => {
    const results = await getSurveyResultsByAppIdWithFilter(appId, fromDate, toDate);
    setSurveyResults(results);
  };

  return (
    <Grid item xs={12}>
    {isLoadingDialog && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
      {!isLoadingDialog && (
      <>
        {openNewDialog && (
          <ApplicationDialog
            orgId={Number(orgId)}
            open={openNewDialog}
            edit={edit}
            app={currentApp}
            onClose={() => {
              setOpenNewDialog(false);
              refetch();
            }}
          />
        )}
        {openArchiveDialog && (
          <ArchiveApplicationDialog
            open={openArchiveDialog}
            onClose={() => {
              setOpenArchive(false);
              if (currentApp.archivedAt) {
                currentApp.archivedAt = null;
              } else {
                currentApp.archivedAt = new Date().toISOString();
              }
              setCurrentApp(currentApp);
            }}
            app={currentApp}
          />
        )}
        {openSurveyDialog && (
          <SurveyDialog
            open={openSurveyDialog}
            surveyResults={surveyResults}
            onClose={() => setOpenSurveyDialog(false)}
          />
        )}
        {openSurveyTableViewDialog && (
          <SurveyTableViewDialog
            open={openSurveyTableViewDialog}
            surveyResults={surveyResults}
            onClose={() => setOpenSurveyTableViewDialog(false)}
            onFetchSurveyResults={fetchSurveyResults}
            currentAppId={currentApp.id}
          />
        )}
      </>
      )}
      <Card>
        <CardHeader title="Applications" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: '100px',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={() => createNewApp()}
              >
                + Add New Application
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Application ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableSkeleton columns={6} />
                ) : (
                  visibleRows?.map((app, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {app.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {app.data.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {app.createTimestamp}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IconButton onClick={() => editApp(app)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleArchivedApp(app)}>
                            {app.archivedAt ? (
                              <ArchiveOutlined />
                            ) : (
                              <ArchiveIcon />
                            )}
                          </IconButton>
                          <IconButton onClick={() => handleOpenSurveyDialog(app)}>
                            <EqualizerIcon />
                          </IconButton>
                          <IconButton onClick={() => handleOpenSurveyTableViewDialog(app)}>
                            <TableViewIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}  // Only standard options here
                      colSpan={6}
                      count={applications?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, newPage) => setPage(newPage)}
                      onRowsPerPageChange={(e) => {
                        const value = Number(e.target.value);
                        setRowsPerPage(value === -1 ? applications?.length || 0 : value);  // Set to total count when "All" is selected
                        setPage(0);
                      }}
                      ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}

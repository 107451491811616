import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import ServiceCard from '../services/ServiceCard'
import { Service } from '../../types/services'
import Filter from '../filter/Filter'
import { useQuery } from '@tanstack/react-query'
import { fetchServices } from '../../api/services/services'
import { useLocation } from 'react-router-dom'
import HelpIcon from '@mui/icons-material/Help'

const DEFAULT_RADIUS = '50'

export default function SearchPage(): JSX.Element {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const zipcode = searchParams.get('zipcode') || ''
  const radius = searchParams.get('radius') || ''

  const {
    data: services,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['services'],
    queryFn: () => fetchServices(zipcode, radius || DEFAULT_RADIUS),
  })
  const [openFilters, setOpenFilters] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState<string[]>([])
  const [filters, setFilters] = useState<string[]>([])
  const [openVideo, setOpenVideo] = useState(false)

  useEffect(() => {
    refetch()
  }, [zipcode, radius, refetch])

  useEffect(() => {
    if (!isLoading) {
      const uniqueTypes = services?.reduce((types, svc) => {
        if (!types.includes(svc.type)) {
          types.push(svc.type)
        }
        return types
      }, [] as string[])
      setFilters(uniqueTypes || [])
    }
  }, [isLoading, setFilters])

  // Check if the user has seen the video before
  useEffect(() => {
    const hasSeenVideo = localStorage.getItem('hasSeenVideo')

    if (!hasSeenVideo) {
      setOpenVideo(true)
      localStorage.setItem('hasSeenVideo', 'true')
    }
  }, [])

  const getFilteredServices = (): Service[] => {
    if (appliedFilters.length) {
      const filtered = services?.filter((svc) =>
        appliedFilters.includes(svc.type),
      )
      return filtered || []
    }
    return services || []
  }

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {openVideo && (
        <Dialog
          open={openVideo}
          onClose={() => setOpenVideo(false)}
          maxWidth='md'
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              minWidth: '80%',
              minHeight: '60%',
            },
          }}
        >
          <DialogTitle>Welcome To HealthOpX</DialogTitle>
          <DialogContent>
            <video controls width='100%' height='auto'>
              <source
                src={`${process.env.PUBLIC_URL}/services_tutorial.mp4`}
                type='video/mp4'
              />
              <track kind='captions' srcLang='en' label='English captions' />
              Your browser does not support the video tag.
            </video>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenVideo(false)}
              variant='outlined'
              sx={{ borderRadius: '100px', textTransform: 'none' }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid
        container
        sx={{
          width: '100%',
          backgroundColor: 'rgb(239,239,239)',
          padding: '16px',
          position: 'relative', // Ensure relative positioning for children
          minHeight: '100vh', // Ensure the container takes up at least the full viewport height
        }}
      >
        <Grid item xs={12}>
          <Filter
            open={openFilters}
            filters={filters}
            setOpen={setOpenFilters}
            setAppliedFilters={setAppliedFilters}
            appliedFilters={appliedFilters}
            resultCount={getFilteredServices().length || 0}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '110px' }}>
          <Button
            variant='outlined'
            onClick={() => setOpenFilters(true)}
            sx={{
              marginRight: '8px',
              borderRadius: '100px',
              textTransform: 'none',
            }}
          >
            Filters ({appliedFilters.length})
          </Button>
          <Button
            variant='contained'
            sx={{ borderRadius: '100px', textTransform: 'none' }}
          >
            Save search
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ color: '#1D1B20' }}>
            Services In Your Area
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Grid item xs={4}>
            <Typography variant='subtitle1' sx={{ color: '#1D1B20' }}>
              {getFilteredServices().length} Result(s)
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'right' }} xs={8}>
            <Typography
              variant='subtitle1'
              sx={{
                color: '#1D1B20',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              Sorted by distance to location
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
          sx={{
            marginTop: '8px',
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            (getFilteredServices() as Service[]).map((svc, idx) => (
              <Grid item key={idx}>
                <ServiceCard
                  service={svc}
                  mediaStyles={{ height: '188px' }}
                  expanded
                />
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      {/* InfoIcon positioned fixed at bottom right */}
      <div
        style={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
          zIndex: 1000,
        }}
      >
        <IconButton
          sx={{ color: 'rgb(120, 45, 174)' }}
          onClick={() => setOpenVideo(true)}
        >
          <HelpIcon sx={{ fontSize: '35px' }} />
        </IconButton>
      </div>
    </div>
  )
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  DialogActions,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Service } from '../../../types/services'
import { UserInfo } from '../../../types/users'
import { MemberStep } from './MemberStep'
import { ServiceStep } from './ServiceStep'
import { ApplicationStep } from './ApplicationStep'

interface ReferralDialogProps {
  orgId: number
  member?: UserInfo
  open: boolean
  onClose: () => void
}

export function ReferralDialog(props: ReferralDialogProps): JSX.Element {
  const { orgId, member } = props
  const [step, setStep] = useState(0)
  const [svc, setSvc] = useState<Service>({} as Service)
  const [user, setUser] = useState<UserInfo>({} as UserInfo)

  useEffect(() => {
    if (member?.id) {
      setStep(1)
      setUser(member)
    }
  }, [member])

  const steps = [
    {
      label: 'Select a Member',
      component: <MemberStep setCurrentUser={setUser} member={member} />,
      completed: Boolean(user.id),
    },
    {
      label: 'Select a Service',
      component: (
        <ServiceStep setCurrentService={setSvc}
          orgId={member?.organizationIds ? member.organizationIds[0] : orgId}
          allServices
        />
      ),
      completed: Boolean(svc.id),
    },
    {
      label: 'Complete Application',
      component: (
        <ApplicationStep
          svcId={svc.id}
          orgId={orgId}
          memberId={Number(user.id)}
          appId={svc.applicationId}
          onComplete={() => {
            setStep(step + 1)
            setUser({} as UserInfo)
            setSvc({} as Service)
          }}
        />
      ),
    },
  ]
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='md' fullWidth>
      <DialogTitle>Add a New Referral</DialogTitle>
      <DialogContent>
        <Stepper activeStep={step}>
          {steps.map((s) => {
            const stepProps: { completed?: boolean } = {
              completed: s.completed,
            }
            return (
              <Step key={s.label} {...stepProps}>
                <StepLabel>{s.label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {step !== steps.length ? (
          steps[step].component
        ) : (
          <Typography sx={{ marginTop: '20px' }}>
            Referral Has Been Submitted
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {step === steps.length ? (
          <Button
            onClick={() => props.onClose()}
            variant='outlined'
            sx={{ borderRadius: '100px', textTransform: 'none' }}
          >
            Close
          </Button>
        ) : (
          <React.Fragment>
            {step !== 0 && (
              <Button
                onClick={() => setStep(step - 1)}
                variant='outlined'
                sx={{ borderRadius: '100px', textTransform: 'none' }}
              >
                Back
              </Button>
            )}
            <Button
              variant='outlined'
              disabled={!steps[step].completed}
              onClick={() => setStep(step + 1)}
              sx={{ borderRadius: '100px', textTransform: 'none' }}
            >
              Next
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'
import { Model } from 'survey-react'
import {
  createApplication,
  updateApplication,
} from '../../api/applications/applications'
import { Application } from '../../types/applications'

interface ApplicationDialogProps {
  orgId?: number
  open: boolean
  editApp?: any
  edit?: boolean
  app?: Application
  onClose: () => void
}

export function ApplicationDialog(props: ApplicationDialogProps): JSX.Element {
  const [isSurveyChanged, setIsSurveyChanged] = useState(false);
  const { edit, app } = props
  const creatorOptions = {
    showJSONEditorTab: false,
    haveCommercialLicense: true,
  }

  let placeholderSurvey = {
    title: 'Enter Title Here ',
    logoPosition: 'right',
    pages: [
      {
        name: 'page1',
        elements: [
          {
            type: 'text',
            name: 'sampleQuestion',
            title: 'Sample Question Title',
          },
        ],
      },
    ],
  }

  if (edit) {
    placeholderSurvey = app?.data
  }

  const creator = new SurveyCreator(creatorOptions)
  creator.isAutoSave = true

  const onSaveHandler = (): void => {
    localStorage.setItem('form-pending', creator.text)
    const editForm = JSON.parse(localStorage.getItem('form-edit') as any)
    if (editForm) {
      editForm.data = JSON.parse(creator.text)
      localStorage.setItem('form-edit', JSON.stringify(editForm))
    }
  }

  creator.saveSurveyFunc = function () {
    onSaveHandler()
  }

  useEffect(() => {
    const editForm = JSON.parse(localStorage.getItem('form-edit') as any)
    if (editForm) {
      setIsSurveyChanged(true);
      creator.JSON = editForm.data
      localStorage.setItem('form-pending', JSON.stringify(editForm.data))
    } else {
      const pendingForm = JSON.parse(
        localStorage.getItem('form-pending') as any,
      )
      creator.JSON = pendingForm || placeholderSurvey
    }
  })

  const handleSubmit = (): void => {
    // const model = new Model(localStorage.getItem('form-pending'))
    const model = JSON.parse(creator.text);
    if (edit) {
      updateApplication({
        id: app?.id,
        uniqueSurveyId: app?.uniqueSurveyId,
        version: app?.version,
        title: model.title,
        data: model,
        language: 'English',
        organizationId: props.orgId,
        originalSurveyId: null,
      }).then(() => {
        localStorage.removeItem('form-edit')
        localStorage.removeItem('form-pending')
        props.onClose()
      })
      return
    }

    createApplication({
      id: null,
      uniqueSurveyId: null,
      version: null,
      title: model.title,
      data: model,
      language: 'English',
      organizationId: props.orgId,
      originalSurveyId: null,
    }).then(() => {
      localStorage.removeItem('form-edit')
      localStorage.removeItem('form-pending')
      props.onClose()
    })
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        localStorage.removeItem('form-edit')
        localStorage.removeItem('form-pending')
        props.onClose()
      }}
      fullScreen
    >
      <DialogTitle>Add a New Application</DialogTitle>
      <DialogContent>
        <SurveyCreatorComponent creator={creator} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.removeItem('form-edit')
            localStorage.removeItem('form-pending')
            props.onClose()
          }}
          variant='outlined'
          sx={{ borderRadius: '100px', textTransform: 'none' }}
        >
          Close
        </Button>
        <Button
          sx={{ borderRadius: '100px', textTransform: 'none' }}
          variant='contained'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { Model } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import * as jsPDFAutoTable from 'jspdf-autotable';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

(window as any).jsPDF = jsPDF;
(window as any).XLSX = XLSX;
(window as any).jsPDFAutoTable = jsPDFAutoTable;

interface SurveyTableViewDialogProps {
  open: boolean;
  onClose: () => void;
  surveyResults: any[];
  onFetchSurveyResults: (appId: number, fromDate?: string, toDate?: string) => void;
  currentAppId: number;
}

export function SurveyTableViewDialog(props: SurveyTableViewDialogProps): JSX.Element {
  const { open, onClose, surveyResults = [], onFetchSurveyResults, currentAppId } = props;

  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const tableViewContainer = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<Model | null>(null);
  const [noData, setNoData] = useState(false);

  // Fetch survey results based on date range
  const handleFetch = async (): Promise<void> => {
    setLoading(true);
    const formattedFromDate = fromDate ? dayjs(fromDate).toISOString() : undefined;
    const formattedToDate = toDate ? dayjs(toDate).toISOString() : undefined;

    await onFetchSurveyResults(currentAppId, formattedFromDate, formattedToDate);
    setLoading(false);
  };

  // Fetch results on dialog open
  useEffect(() => {
    if (open) {
      handleFetch();
    }
  }, [open]);

  useEffect(() => {
    if (surveyResults.length > 0) {
      processSurveyResults(surveyResults);
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [surveyResults]);

  const processSurveyResults = (results: any[] = []) => {
    if (!results || results.length === 0 || !tableViewContainer.current) return;

    // Extract questions from surveyResults
    const questions = new Set<string>();
    results.forEach(result => {
      Object.keys(result.data).forEach(question => questions.add(question));
    });

    // Survey JSON schema
    const surveyJson = {
      questions: Array.from(questions).map(question => ({
        name: question,
        type: 'text',
        title: question.charAt(0).toUpperCase() + question.slice(1).replace(/([A-Z])/g, ' $1')
      })),
    };

    try {
      const newModel = new Model(surveyJson);
      setModel(newModel);
      const resultsData = results.map(result => result.data);

      // Render Tabulator
      const tabulator = new Tabulator(
        newModel,
        resultsData,
        {
          showLogicTab: false,
          haveCommercialLicense: true,
          downloadButtons: ['pdf', 'csv', 'xlsx'],
        }
      );
      tabulator.render(tableViewContainer.current);
    } catch (error) {
      console.error('Error creating table view:', error);
    }
  };

  const isFetchDisabled = !fromDate || !toDate;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogTitle>Survey Results</DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          padding: '10px 20px',
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
              disabled={loading}
            />
            <DatePicker
              label="To Date"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
              disabled={loading}
            />
          </LocalizationProvider>
          <Button 
            variant="contained"
            color="primary"
            disabled={isFetchDisabled} 
            onClick={handleFetch}>Search</Button>
        </Box>
        {/* Render survey results */}
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress sx={{ marginTop: 2 }} />
          </Box>
        ) : noData ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              minHeight: '200px',
            }}
          >
            <Typography variant="h6">No data available</Typography>
          </Box>
        ) : (
          <div ref={tableViewContainer} style={{ width: '100%' }}></div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={{ borderRadius: '100px', textTransform: 'none' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

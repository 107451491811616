import {
  TextField,
  Grid,
  IconButton,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Typography,
  Divider,
  MenuItem,
  Switch,
  FormHelperText,
} from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchServices } from '../../../api/services/services'
import { Search, ExpandMore } from '@mui/icons-material'
import { Service, ServiceHours } from '../../../types/services'
import { serviceTypes } from '../ServiceDialog'
import { formatServiceTypes } from '../../../patients-views/services/util'

interface ServiceStepProps {
  orgId: number
  setCurrentService: (svc: Service) => void
  allServices: boolean
}

export function ServiceStep(props: ServiceStepProps): JSX.Element {
  const { orgId, allServices } = props
  const [zip, setZip] = useState('')
  const [rad, setRad] = useState('10')
  const [type, setType] = useState('')
  const [selected, setSelected] = useState(0)
  const [useMemberOrg, setUseMemberOrg] = useState(true)

  const { data: services, refetch: refetchServices } = useQuery({
    queryKey: ['services', zip, rad, orgId, useMemberOrg, type],
    queryFn: (): Promise<Service[]> => {
      const organizationId = useMemberOrg ? orgId.toString() : undefined
      return fetchServices(zip, rad, organizationId, false, type)
    },
  })


  const searchServices = (): void => {
    refetchServices()
  }

  const renderServiceSummary = (svc: Service): string => {
    return `${svc.name} - ${svc.type}`
  }

  const renderServiceHours = (hours: ServiceHours): string => {
    let day = ''
    if (hours.dayOfWeek === 1) day = 'Monday'
    if (hours.dayOfWeek === 2) day = 'Tuesday'
    if (hours.dayOfWeek === 3) day = 'Wednesday'
    if (hours.dayOfWeek === 4) day = 'Thursday'
    if (hours.dayOfWeek === 5) day = 'Friday'
    if (hours.dayOfWeek === 6) day = 'Saturday'
    if (hours.dayOfWeek === 7) day = 'Sunday'

    return `${day} ${hours.startTime}-${hours.endTime}`
  }

  const renderServiceDetails = (svc: Service): JSX.Element => {
    return (
      <React.Fragment>
        <Typography display='block'>Name:</Typography>
        <Typography display='block'>{svc.name}</Typography>
        <Typography display='block'>Description:</Typography>
        <Typography display='block'>{svc.description}</Typography>
        <Typography display='block'>Service Type:</Typography>
        <Typography display='block'>{svc.type}</Typography>
        <Typography display='block'>Location Type:</Typography>
        <Typography display='block'>{svc.locationType}</Typography>
        <Divider />
        <Typography>Location:</Typography>
        <Typography>{svc.addressLine1}</Typography>
        <Typography>{svc.addressLine2}</Typography>
        <Typography>
          {svc.city} {svc.state} {svc.zipCode}
        </Typography>
        <Divider />
        <Typography>Hours of Operation:</Typography>
        <Grid container>
          {svc.serviceHours.map((hours, idx) => {
            return (
              <Grid item xs={12} key={idx}>
                {renderServiceHours(hours)}
              </Grid>
            )
          })}
        </Grid>
        <Divider />
        <Typography>Eligibility Requirements</Typography>
        {svc.eligibilityRequirements &&
          svc.eligibilityRequirements
            ?.split(',')
            .map((req, idx) => <Typography key={idx}>{req}</Typography>)}
      </React.Fragment>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      justifyContent="center"
      sx={{ marginTop: '10px', marginBottom: '10px' }}
    >

      <Grid item xs={3}>
        <FormHelperText sx={{ fontWeight: 'bold' }}>Current Organization <Switch
          checked={useMemberOrg}
          onChange={() => setUseMemberOrg(!useMemberOrg)}
          inputProps={{ 'aria-label': 'Use Member Organization' }}
        /></FormHelperText>
      </Grid>
      <Grid item xs={3}>
        <TextField
          margin='dense'
          variant='outlined'
          type='number'
          label='ZipCode'
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          margin='dense'
          variant='outlined'
          label='Radius'
          type='number'
          value={rad}
          onChange={(e) => setRad(e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          margin='dense'
          label='Service Type'
          variant='outlined'
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {serviceTypes.map((type, idx) => (
            <MenuItem key={idx} value={type}>
              {formatServiceTypes(type)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={searchServices}
          size='large'
          sx={{ marginTop: '10px' }}
        >
          <Search />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <List>
          {services?.map((svc, idx) => {
            return (
              <ListItem
                key={idx}
                secondaryAction={
                  <Button
                    onClick={() => {
                      setSelected(svc.id)
                      props.setCurrentService(svc)
                    }}
                    variant={selected === svc.id ? 'contained' : 'outlined'}
                    sx={{
                      borderRadius: '100px',
                      textTransform: 'none',
                    }}
                  >
                    {selected === svc.id ? 'Selected' : 'Select'}
                  </Button>
                }
              >
                <Accordion sx={{ width: '80%' }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {renderServiceSummary(svc)}
                  </AccordionSummary>
                  <AccordionDetails>
                    {renderServiceDetails(svc)}
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

import React, { useState, useEffect } from 'react'
import { Grid, TextField, Button, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BASE_API_URL } from '../api/constants'

export default function ForgotPassword(): JSX.Element {
  const [email, setEmail] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)

  const navigate = useNavigate()

  // validate password
  const validatePassword = (password: string): boolean => {
    const minLength = 8
    const hasNumber = /\d/.test(password)
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
      password,
    )
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)

    return (
      password.length >= minLength &&
      hasNumber &&
      hasSpecialCharacter &&
      hasUpperCase &&
      hasLowerCase
    )
  }

  useEffect(() => {
    setIsPasswordValid(validatePassword(password))
  }, [password])

  // Send Authorization Code
  const handleForgotPassword = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_API_URL}/v1/auth/forgotPassword/${email}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (response.ok) {
        setSuccessMsg('An authorization code has been sent to your email.')
        setErrorMsg('')
        setIsCodeSent(true)
      } else {
        const errorData = await response.json()
        setErrorMsg(
          errorData.message ||
            'Failed to send the authorization code. Please try again.',
        )
        setSuccessMsg('')
      }
    } catch (error) {
      setErrorMsg('Network error. Please try again.')
      setSuccessMsg('')
    }
  }

  // Verify confirmation code and reset password
  const handleVerifyCodeAndResetPassword = async (): Promise<void> => {
    if (!isPasswordValid) {
      setErrorMsg('Passwords does not meet the required criteria.')
      return
    }

    if (password !== confirmPassword) {
      setErrorMsg('Passwords do not match.')
      return
    }

    try {
      const response = await fetch(
        `${BASE_API_URL}/v1/auth/forgotPasswordVerify`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
            token: confirmationCode,
          }),
        },
      )

      if (response.ok) {
        setSuccessMsg(
          'Password has been reset successfully. You can now log in.',
        )
        setErrorMsg('')
        setTimeout(() => navigate('/login'), 2000)
      } else {
        const errorData = await response.json()
        setErrorMsg(
          errorData.message || 'Failed to reset password. Please try again.',
        )
        setSuccessMsg('')
      }
    } catch (error) {
      setErrorMsg('Network error. Please try again.')
      setSuccessMsg('')
    }
  }

  return (
    <Grid container sx={{ marginTop: '32px', padding: '32px 22px 32px 22px' }}>
      <Grid
        item
        xs={12}
        justifyContent='center'
        display='flex'
        sx={{ marginBottom: '32px' }}
      >
        <Typography variant='h4' sx={{ color: '#6750A4' }}>
          {isCodeSent ? 'Reset Password' : 'Forgot Password'}
        </Typography>
      </Grid>

      {/* Enter Email */}
      {!isCodeSent && (
        <React.Fragment>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Enter Email'
              variant='filled'
              error={Boolean(errorMsg)}
              helperText={
                errorMsg.length ? errorMsg : successMsg.length ? successMsg : ''
              }
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '32px' }}>
            <Button
              fullWidth
              variant='contained'
              sx={{ borderRadius: '100px', textTransform: 'none' }}
              onClick={handleForgotPassword}
              disabled={!email}
            >
              Send Authorization Code
            </Button>
          </Grid>
        </React.Fragment>
      )}

      {/* Enter Token Code and New Password */}
      {isCodeSent && (
        <React.Fragment>
          <Grid item xs={12} sx={{ marginBottom: '16px' }}>
            <TextField
              fullWidth
              label='Enter Confirmation Code'
              variant='filled'
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Enter New Password'
              variant='filled'
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '16px' }}>
            <TextField
              fullWidth
              label='Confirm New Password'
              variant='filled'
              type='password'
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>

          {/* Display error message */}
          {errorMsg && (
            <Grid item xs={12}>
              <Typography color='error' variant='body1'>
                {errorMsg}
              </Typography>
            </Grid>
          )}

          {/* Password Validation Checklist */}
          <Grid item xs={12} sx={{ marginTop: '16px' }}>
            <Typography variant='body1'>Password must include:</Typography>
            <ul>
              <li>
                {password.length >= 8
                  ? '✅ Password minimum length: 8 characters'
                  : '❌ Password minimum length: 8 characters'}
              </li>
              <li>
                {/\d/.test(password)
                  ? '✅ At least 1 number'
                  : '❌ Must contain at least 1 number'}
              </li>
              <li>
                {/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
                  ? '✅ At least 1 special character'
                  : '❌ Must contain at least 1 special character'}
              </li>
              <li>
                {/[A-Z]/.test(password)
                  ? '✅ At least 1 uppercase letter'
                  : '❌ Must contain at least 1 uppercase letter'}
              </li>
              <li>
                {/[a-z]/.test(password)
                  ? '✅ At least 1 lowercase letter'
                  : '❌ Must contain at least 1 lowercase letter'}
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '16px' }}>
            <Button
              fullWidth
              variant='contained'
              sx={{ borderRadius: '100px', textTransform: 'none' }}
              onClick={handleVerifyCodeAndResetPassword}
              disabled={!confirmationCode || !password || !confirmPassword}
            >
              Reset Password
            </Button>
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <Link
          variant='body1'
          display='flex'
          justifyContent='center'
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/login')}
        >
          Back to Sign In
        </Link>
      </Grid>
    </Grid>
  )
}
